<template>
    <section class="pt-4">
        <div class="row mx-0 align-items-end mb-4">
            <div class="col-3 pb-1">
                <p class="pl-3 text-general f-14">Seleccionar fecha</p>
                <el-date-picker
                v-model="fecha"
                type="date"
                class="w-100"
                size="small"
                placeholder="Seleccionar Fecha"
                :clearable="false"
                value-format="yyyy-MM-dd"
                format="dd MMMM yyyy"
                @change="getDeliverys"
                />
            </div>
            <div class="circled-delivery d-middle-center mt-3">
                <i v-if="idDelivery == null" class="icon-moped text-white f-20" />
                <img v-else :src="delivery.foto" class="w-100 h-100 obj-cover rounded-circle" />
            </div>
            <div class="col-3 pb-1">
                <el-select v-model="idDelivery" placeholder="Select" size="small" class="w-100" @change="setDelivery">
                    <el-option
                    v-for="item in deliverys"
                    :key="`del-${item.id}`"
                    :label="item.nombre"
                    :value="item.id"
                    />
                </el-select>
            </div>
            <div type="button" class="btn-general px-2 mr-2" @click="getDataDelivery">
                Aplicar
            </div>
            <div class="col bg-white d-middle text-general br-8" style="height: 48px;">
                <div class="col-4">
                    <b class="f-30">
                        {{ pedidosEntregados.length }}
                    </b>
                    <span class="f-18 f-400">
                        Entregas
                    </span>
                </div>
                <div class="border h-70 my-2" />
                <div class="col">
                    <b class="f-30">
                        {{ separadorNumero(totalRecibido) }}
                    </b>
                    <span class="f-18 f-400">
                        Recibido
                    </span>
                </div>
            </div>
        </div>
        <div class="row mx-0 position-relative">
            <div class="col-12 bg-white br-4 d-flex align-items-center" style="height:93px;">
                <div class="col-12">
                    <div class="rounded-line w-100 position-relative">
                        <!-- Positions -->
                        <i
                        v-for="(t, idx) in timeLine"
                        :key="`post-${idx}`"
                        class="icon-map-marker-outline f-20 position-delivery cr-pointer text-general"
                        :style="`left:${t.punto}%;`"
                        @click="getEntrega(t.id)"
                        />
                        <!-- position hours  -->
                        <div class="text-general f-500 position-absolute f-14 hour-0" style="top:9px;">
                            {{ horaMin | helper-fecha('hh:mm a') }}
                        </div>
                        <div class="text-general f-500 position-absolute f-14 hour-1" style="top:9px;">
                            {{ horaPromedio | helper-fecha('hh:mm a') }}
                        </div>
                        <div class="text-general f-500 position-absolute f-14 hour-2" style="top:9px;">
                            {{ horaMax | helper-fecha('hh:mm a') }}
                        </div>
                        <!-- Positions containers -->
                    </div>
                </div>
            </div>
        </div>
        <div class="container-info-del border w-100 px-3 py-2">
            <div class="row mx-0 h-100 align-items-center justify-content-between">
                <div class="col-auto px-3 d-flex">
                    <img :src="entrega.is_tendero ? entrega.logo_lechero : entrega.avatar_cliente" width="48" height="48" class="obj-cover rounded-circle" />
                    <div class="col px-2">
                        <p class="text-general f-14 f-500">{{ entrega.is_tendero ? entrega.lechero: entrega.cliente }}</p>
                        <p class="text-gris">{{ entrega.is_tendero ? $config.vendedor : $config.cliente }}</p>
                    </div>
                </div>
                <div class="col-auto f-14 text-general f-400">
                    <div class="d-middle">
                        <i class="icon-pedidos f-17" />
                        <p>
                            <span><b>AP:</b>{{ entrega.id_admin_pedido }}</span>
                            <br />
                            <span v-show="entrega.is_tendero == 0"><b>P:</b>{{ entrega.id_pedido }}</span>
                        </p>
                    </div>
                </div>
                <div class="col-auto f-14 text-general f-400">
                    <i class="icon-comision f-17 mr-2" />
                    {{ convertMoney(entrega.valor_final, entrega.idm_moneda) }}
                </div>
                <div class="col-auto f-14 text-general f-400">
                    <i class="icon-cedis f-17" />
                    {{ entrega.cedis }}
                </div>
                <div class="col-auto f-14 text-general f-400">
                    <i class="icon-clock f-17" />
                    {{ entrega.hora_programada }} (Programada)
                </div>
                <div class="col-auto f-14 text-general f-400">
                    <i class="icon-clock-end f-17" />
                    {{ entrega.hora_entrega | helper-fecha('HH:mm') }} (Entrega)
                </div>
            </div>
        </div>
        <div class="col-12 px-0 bg-white">
            <mapa-google ref="GoogleMaps" height="calc(100vh - 332px)" />
            <div class="row mx-0 align-items-center py-3 px-3">
                <div class="box-cliente">
                    <i class="icon-cliente f-18" />
                </div>
                <p class="col-auto px-0 text-general ml-2 f-15 f-400">
                    Ubicación del {{ $config.cliente }}
                </p>
                <div class="box-cliente ml-3">
                    <i class="icon-leechero-cap f-18" />
                </div>
                <p class="col-auto px-0 text-general ml-2 f-15 f-400">
                    Ubicación del {{ $config.vendedor }}
                </p>
                <div class="box-truck ml-3">
                    <i class="icon-truck f-18" />
                </div>
                <p class="col-auto px-0 text-general ml-2 f-15 f-400">
                    Ubicación del delivery "20" minutos antes y después de la entrega
                </p>
            </div>
        </div>
        <div class="row mx-0 mt-5">
            <div class="col-12 bg-white text-general f-600 f-17 py-3 br-t-8">
                Entregas realizadas durante el 15 de Diciembre de 2021
            </div>
            <div class="col-12 px-0 bg-white">
                <el-table
                :data="pedidosEntregados"
                header-row-class-name="text-general"
                style="width: 100%"
                >
                    <el-table-column prop="id_admin_pedido" label="Pedido" class-name="text-center" />
                    <el-table-column :label="$config.vendedor" class-name="text-center">
                        <template slot-scope="{row}">
                            <div class="d-middle">
                                <el-tooltip v-show="row.cliente_entrega_directa" placement="bottom" effect="light">
                                    <div slot="content" style="max-width:280px;">
                                        {{ $config.cliente }}: {{ row.cliente }}
                                    </div>
                                    <i class="icon-cliente" />
                                </el-tooltip>
                                <p>
                                    {{ row.lechero }}
                                </p>
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column prop="cedis" label="Cedis" class-name="text-center" />
                    <el-table-column label="Valor Pedido" class-name="text-center">
                        <template slot-scope="{row}">
                            <p>
                                {{ convertMoney(row.valor_final, row.idm_moneda) }}
                            </p>
                        </template>
                    </el-table-column>
                    <el-table-column label="Hora Programada" class-name="text-center">
                        <template slot-scope="{row}">
                            <p>
                                {{ row.hora_programada | helper-fecha('hh:mm a') }}
                            </p>
                        </template>
                    </el-table-column>
                    <el-table-column label="Hora Entrega" class-name="text-center">
                        <template slot-scope="{row}">
                            <p>
                                {{ row.created_at | helper-fecha('hh:mm a') }}
                            </p>
                        </template>
                    </el-table-column>
                    <el-table-column prop="total_peso" label="Peso (Kg)" class-name="text-center" />
                    <el-table-column prop="total_volumen" label="Peso Volumetrico (m3)" class-name="text-center" />
                    <el-table-column label="Dinero Efectivo" class-name="text-center">
                        <template slot-scope="{row}">
                            <p v-if="row.tipo_pago == 11 || row.tipo_pago ==12">
                                {{ convertMoney(row.pago_dinero, row.idm_moneda) }}
                            </p>
                            <p v-else> - </p>
                        </template>
                    </el-table-column>
                    <el-table-column label="Dinero por transferencia" class-name="text-center">
                        <template slot-scope="{row}">
                            <p v-if="row.tipo_pago == 21 || row.tipo_pago ==22 || row.tipo_pago == 41">
                                {{ convertMoney(row.pago_dinero, row.idm_moneda) }}
                            </p>
                            <p v-else> - </p>
                        </template>
                    </el-table-column>
                    <el-table-column prop="distancia" label="Distancia (Km)" class-name="text-center" />
                    <el-table-column label="Calificación" class-name="text-center">
                        <template slot-scope="{row}">
                            <p>
                                {{ agregarSeparadoresNumero(row.calificacion,1) }}
                                <i class="icon-star f-20 text-warning" />
                            </p>
                        </template>
                    </el-table-column>
                </el-table>
            </div>
        </div>
        <div class="row mx-0 mt-3 py-3 br-8 bg-white">
            <graficas-entregas ref="graficaEntregasVsDinero" />
        </div>
        <div class="row mx-0 mt-3 py-3 br-8 bg-white">
            <graficas-entregas ref="graficaEntregasVsPesos" tabs />
        </div>
    </section>
</template>

<script>
import Entregas from "~/services/delivery/index";
import moment from 'moment'
export default {
    components: {
        graficasEntregas: () => import ('./components/graficasEntregas')
    },
    data(){
        return {
            fecha:'',
            deliverys:[],
            delivery:{},
            idDelivery:null,
            timeLine:[],
            horaMin:'',
            horaMax:'',
            horaPromedio:'',
            entrega:{},
            pedidosEntregados:[],
            totalRecibido:0

        }
    },
    methods:{
        async getDeliverys(fecha){
            try {

                const {data} = await Entregas.listarDeliverys(fecha)
                this.delivery = {}
                this.deliverys = []
                this.idDelivery = null,
                this.deliverys = data.deliverys

            } catch (error){
                this.error_catch(error)
            }
        },
        setDelivery(id){
            const delivery = this.deliverys.find(o=>o.id == id)
            this.delivery = delivery
        },
        async getDataDelivery(){
            try {
                if(this.idDelivery === null){
                    this.notificacion('Advertencia','Por favor seleccione un delivery','warning')
                    return
                }
                const {data} = await Entregas.getDataDelivery(this.idDelivery, this.fecha)
                this.timeLine = data.timeline
                this.horaMin = data.hora_minima
                this.horaMax = data.hora_maxima
                this.horaPromedio = data.hora_promedio
                this.pedidosEntregados = data.entregas
                this.totalRecibido = data.total_recibido
                this.getEntrega(this.timeLine[0].id)

                this.$refs.graficaEntregasVsDinero.initGraficaDinero(this.idDelivery)
                this.$refs.graficaEntregasVsPesos.initGraficaPesos(this.idDelivery)


            } catch (error){
                this.error_catch(error)
            }
        },
        async getEntrega(idEntrega){
            try {
                const {data} = await Entregas.getDataEntrega(idEntrega)
                this.entrega = data.entrega
                var locations = data.ubicaciones.map(e => ({
                    lat: parseFloat(e.latitud),
                    lng: parseFloat(e.longitud),
                    info:moment(e.created_at).format('hh:mm a'),
                    icon: e.principal ? this.entrega.is_tendero ? '/img/icons/marker_tendero.svg' : '/img/icons/marker_cliente.svg' : '/img/icons/delivery_ubicacion.svg',

                }))
                this.$refs.GoogleMaps.initMapMarkerClusterer(locations, false, true)
            } catch (error){
                this.error_catch(error)
            }
        }
    }
}
</script>
<style lang="scss" scoped>
.circled-delivery{
    width: 38px;
    height: 38px;
    background-color: #707070;
    border-radius: 50%;
}
.rounded-line{
    border-radius: 3px;
    border: 1px solid #DBDBDB;
    background: #F5F5F5;
    height: 6px;
    .position-delivery{
        position: absolute;
        top: -28px;
        left: -1%;
    }
    .hour-0{
        left: 0%;
    }
    .hour-1{
        left: 50%;
    }
    .hour-2{
        left: 94%;
    }
}
.container-info-del{
    background: #FFFFFF;
    //top: 38px;
    min-height: 70px;
    //position: absolute;
    border-radius: 4px;
    //border: 1px solid #28D07B;
    box-shadow: 0px 3px 6px #00000029;
}
.text-green{
    color: #28D07B;
}
.box-cliente{
    width: 32px;
    height: 32px;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--text-general);
    color: #FFFFFF;
}
.box-truck{
    width: 32px;
    height: 32px;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--color-general);
    color: #FFFFFF;
}
.floating-box{
    left:2.5%;
    top:86px;
    width:95%;
}
</style>
